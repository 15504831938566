// import { Container } from "../../base/layout"
import cx from "classnames"
import { usePropsObject } from "@/lib/contexts/appContext"
import Card from "@/common/cards/article/article"
import Block from "@/components/wrappers/block"
import styles from "./article-teasers.module.css"

export default function ArticleTeasers({ block }) {
  const { attrs } = block
  const { theme } = usePropsObject()

  if (attrs.articles.length === 0) {
    return null
  }

  return (
    <Block
      block={block}
      className={cx({ "text-white": theme?.bgColor === "black" })}>
      {(attrs.heading || attrs.category.label) && (
        <h3 className={styles.heading}>
          {attrs.heading}
          <a className={styles.category} href={attrs.category.url}>
            {attrs.category.label}
          </a>
        </h3>
      )}
      <ul className={styles.blockGrid}>
        {attrs.articles.map((post, index) => {
          return (
            <li key={index}>
              <Card post={post} />
            </li>
          )
        })}
      </ul>
    </Block>
  )
}
